import './App.css';
import { LuConstruction } from 'react-icons/lu';

function App() {
  return (
    <div className="App">
      <div className="container">
        <span id="main-text">
          under construction
        </span>
        <div id="main-icon">
          <LuConstruction size={36} color='white' />
        </div>
      </div>
    </div>
  );
}

export default App;
